import React, { useEffect, useState } from "react";
import { Child } from "../types/Child";
import { AppUser } from "../types/AppUsers";
import { Admin } from "../types/Admin";
import { TeamMember } from "../types/TeamMember";
import { Group } from "../types/Group";
import { CircularProgress, Link } from "@mui/material";
import createImportWorkbook from "./ExportExcelLink/createImportWorkbook";

const ExportExcelLink = ({
  children,
  guardians,
  admins,
  teamMembers,
  groups,
}: {
  children: Child[];
  guardians: AppUser[];
  admins: Admin[];
  teamMembers: TeamMember[];
  groups: Group[];
}) => {
  const [downloadFile, setDownloadFile] = useState<string>();
  useEffect(() => {
    const callback = async () => {
      const workbook = createImportWorkbook(
        children,
        guardians,
        admins,
        teamMembers,
        groups
      );
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {});
      const file = new File([blob], "Export.xlsx", {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      // Create a new object URL for the File object
      setDownloadFile(window.URL.createObjectURL(file));
    };
    if (children.length === 0) {
      return () => null;
    }
    setDownloadFile(undefined);
    const handler = window.setTimeout(callback, 1000);
    return () => window.clearTimeout(handler);
  }, [children, guardians, admins, teamMembers, groups]);

  if (children.length === 0) return null;
  if (!downloadFile) return <CircularProgress sx={{ margin: "auto" }} />;
  return (
    <Link
      variant="button"
      href={downloadFile}
      color="secondary"
      sx={{
        margin: "auto",
        marginTop: "2em",
        marginBottom: "2em",
        borderRadius: "2em",
        backgroundColor: "#ff8158",
        color: "white",
        padding: "0.5em 1em",
      }}
    >
      CARE Kinder-Import Herunterladen
    </Link>
  );
};

export default ExportExcelLink;
